import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useWeb3React } from '@web3-react/core';
import { injected } from 'src/connectors';
import {
  getBalanceOf,
  getContract,
  getWeb3ContractObject,
  getWeb3Obj,
} from 'src/utils';
import { TESTNFTAddress } from 'src/constants';
import TestNFTABI from 'src/abis/TestNFTABI.json';

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('creatturAccessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('creatturAccessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.localStorage.getItem('creatturAccessToken');
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData] = useState({});
  const [nftPrice, setNftPrice] = useState(0);
  const [maxNftSuppy, setMaxNftSuppy] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [mintPercentage, setMintPercentage] = useState(0);
  const [userNFTList, setUserNFTList] = useState([]);
  const [balanceOfValue, setBalanceOfValue] = useState();
  const [hasFinalSaleStarted, sethasFinalSaleStarted] = useState(false);
  const [maxMintQuantity, setMaxMintQuantity] = useState(0);
  const [allNftList, setallNftList] = useState([]);
  const { activate, account, library, chainId } = useWeb3React();

  const walletConnect = async () => {
    try {
      activate(injected, undefined, true).catch((error) => {
        if (error) {
          console.log('ERROR', error);
          const errorMSG = error.message; //+ ' Please install Metamask';
          alert(errorMSG);
          activate(injected);
        }
      });
    } catch (error) {
      console.log('ERROR', error);
    }
  };

  const getBasicDetailsHandler = async () => {
    try {
      allNFTListHandler();
      getBalanceOfFun();
      const web3 = await getWeb3Obj();
      const contract = await getWeb3ContractObject(TestNFTABI, TESTNFTAddress);
      //totalSupply
      const totalSupplyL = await contract.methods.totalSupply().call();
      const MAX_MEERKAT_L = await contract.methods.MAX_MEERKAT_SUPPLY().call();
      console.log('contract', contract.methods);
      let percentage =
        (parseFloat(totalSupplyL) / parseFloat(MAX_MEERKAT_L)) * 100;
      //hasFinalSaleStarted
      const hasFinalSaleStarted = await contract.methods
        .hasFinalSaleStarted()
        .call();
      if (hasFinalSaleStarted) {
        const MEERKAT_PRICE = await contract.methods.MEERKAT_PRICE().call();
        setNftPrice(web3.utils.fromWei(MEERKAT_PRICE));
      } else {
        const RESERVE_PRICE = await contract.methods.RESERVE_PRICE().call();
        setNftPrice(web3.utils.fromWei(RESERVE_PRICE));
      }
      const MAX_MINT_L = await contract.methods.MAX_MINT().call();
      setMaxMintQuantity(MAX_MINT_L);
      sethasFinalSaleStarted(hasFinalSaleStarted);
      setMaxNftSuppy(MAX_MEERKAT_L);
      setTotalSupply(totalSupplyL);
      setMintPercentage(percentage);
    } catch (error) {
      console.log('ERROR', error);
    }
  };

  const allNFTListHandler = async () => {
    setUserNFTList([]);
    const contract = await getWeb3ContractObject(TestNFTABI, TESTNFTAddress);
    const txaCount = await contract.methods.totalSupply().call();
    try {
      for (let i = 1; i <= parseInt(txaCount); i++) {
        const tokenURI = await contract.methods.tokenURI(i.toString()).call();

        const res = await axios.get(tokenURI);

        if (res.status === 200) {
          setallNftList((prev) => [
            ...prev,
            { id: i.toString(), nfdData: res.data },
          ]);
        }
      }
    } catch (error) {
      console.log('ERROR', error);
    }
  };

  useEffect(() => {
    getBasicDetailsHandler();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (account) {
      walletConnect();
    }
  }, [account, chainId]); // eslint-disable-line react-hooks/exhaustive-deps

  const getNftList = async (txaCount) => {
    setUserNFTList([]);
    const contract = getContract(TESTNFTAddress, TestNFTABI, library, account);

    try {
      for (let i = 0; i < parseInt(txaCount); i++) {
        const ownerByIndex = await contract.tokenOfOwnerByIndex(account, i);
        const tokenURI = await contract.tokenURI(ownerByIndex.toString());
        const res = await axios.get(tokenURI);
        if (res.status === 200) {
          setUserNFTList((prev) => [
            ...prev,
            { id: ownerByIndex.toString(), nfdData: res.data },
          ]);
        }
      }
    } catch (error) {
      console.log('ERROR', error);
    }
  };

  useEffect(() => {
    if (balanceOfValue && parseInt(balanceOfValue) > 0) {
      getNftList(balanceOfValue);
    }
  }, [balanceOfValue, account, chainId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (account) {
      getBalanceOfFun();
    }
  }, [account, chainId]); // eslint-disable-line react-hooks/exhaustive-deps

  async function getBalanceOfFun() {
    setBalanceOfValue(await getBalanceOf(TestNFTABI, TESTNFTAddress, account));
  }

  let data = {
    userLoggedIn: isLogin,
    userData,
    nftPrice,
    maxNftSuppy,
    totalSupply,
    mintPercentage,
    balanceOfValue,
    userNFTList,
    maxMintQuantity,
    hasFinalSaleStarted,
    allNftList,
    walletConnect: () => walletConnect(),
    getBalanceOfFun: () => getBalanceOfFun(),
    getBasicDetailsHandler: () => getBasicDetailsHandler(),
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
